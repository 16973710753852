import React, { useMemo } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import moment from 'moment';
import "css/common/DateHeatmap.css";


const DateHeatmapDay = ({ data, year, scope }) => {
	const daysOfYear = useMemo(() => {
	    const days = Array(7 * 53).fill(null); // Prepare an empty grid
	    let currentDate = moment(`${year}-01-01`);
	    const endDate = moment(`${year}-12-31`);

	    // Determine the starting position based on the weekday of January 1st (Monday = 0)
	    const startDayIndex = (currentDate.isoWeekday() - 1) % 7; // Monday as the top row
	    let dayIndex = startDayIndex;

	    while (currentDate.isSameOrBefore(endDate)) {
	      const formattedDate = currentDate.format('MM-DD-YYYY');
	      days[dayIndex] = formattedDate;
	      currentDate.add(1, 'day');
	      dayIndex += 1; // Populate downward by column, then shift by row
	    }

	    return days;
	}, [year]);

	return (
		<div className="DateHeatmap_DayGrid">
			{
				daysOfYear.map((day, index) => {
					const isCellEmpty = day == null;
					const count = day in data ? data[day] : 0;
					const id = `cell${index}`;

					return (
						<div key={index}>
							{
								isCellEmpty ? (
									<EmptyCell />
								) : (
									<>
										<Cell
								        	id={id}
								        	scope={scope}
								        	count={count}
							        	/>
							        	<UncontrolledPopover
							        		target={id}
							        		trigger="click"
							        	>
							        		<PopoverHeader>
							        			{day}
							        		</PopoverHeader>
							        		<PopoverBody>
							        			{count} Performance(s)
							        		</PopoverBody>
							        	</UncontrolledPopover>
									</>
								)
							}
			        	</div>
					);
		      	})
			}
		</div>
	);
}

const DateHeatmapMonth = ({ data, year, scope }) => {
	const monthsOfYear = useMemo(() => {
	    const months = [];
	    let currentDate = moment(`${year}-01-01`);

	    for (let i = 0; i < 12; i++) {
	      const formattedDate = currentDate.format('MM-YYYY');
	      months.push(formattedDate);
	      currentDate.add(1, 'month');
	    }

	    return months;
	}, [year]);

	return (
		<div className="DateHeatmap_MonthGrid">
			{
				monthsOfYear.map((month, index) => {
					const isCellEmpty = month == null;
					const count = month in data ? data[month] : 0;
					const id = `cell${index}`;

					return (
						<div key={index}>
							{
								isCellEmpty ? (
									<EmptyCell />
								) : (
									<>
										<Cell
								        	id={id}
								        	scope={scope}
								        	count={count}
							        	/>
							        	<UncontrolledPopover
							        		target={id}
							        		trigger="click"
							        	>
							        		<PopoverHeader>
							        			{month}
							        		</PopoverHeader>
							        		<PopoverBody>
							        			{count} Performance(s)
							        		</PopoverBody>
							        	</UncontrolledPopover>
									</>
								)
							}
			        	</div>
					);
		      	})
			}
		</div>
	);
}

const DateHeatmapYear = ({ data, year, scope }) => {

	const count = year in data ? data[year] : 0;
	const id = `cell${year}`;

	return (
		<div className="DateHeatmap_MonthGrid">
			<Cell
	        	id={id}
	        	scope={scope}
	        	count={count}
        	/>
        	<UncontrolledPopover
        		target={id}
        		trigger="click"
        	>
        		<PopoverHeader>
        			{year}
        		</PopoverHeader>
        		<PopoverBody>
        			{count} Performance(s)
        		</PopoverBody>
        	</UncontrolledPopover>
									
		</div>
	);
}

const DateHeatmap = ({ data, year, scope }) => {
	if (scope == "day") {
		return <DateHeatmapDay data={data} year={year} scope={scope} />
	}

	if (scope == "month") {
		return <DateHeatmapMonth data={data} year={year} scope={scope} />
	}

	if (scope == "year") {
		return <DateHeatmapYear data={data} year={year} scope={scope} />
	}

	return null;
}

const EmptyCell = () => {
	return (
		<div className="DateHeatmap_Cell_Empty"></div>
	)
}

const Cell = ({ id, scope, count }) => {
	let cellClass = "DateHeatmap_DayCell";
	let countClass = "";


	if (scope == "day") {
		cellClass = "DateHeatmap_DayCell";
		if (count > 2) {
			countClass = "DateHeatmap_Cell_2";
		} else if (count > 1) {
			countClass = "DateHeatmap_Cell_1";
		} else if (count > 0) {
			countClass = "DateHeatmap_Cell_0";
		}
	}
	if (scope == "month") {
		cellClass = "DateHeatmap_MonthCell";
		if (count > 30) {
			countClass = "DateHeatmap_Cell_2";
		} else if (count > 15) {
			countClass = "DateHeatmap_Cell_1";
		} else if (count > 0) {
			countClass = "DateHeatmap_Cell_0";
		}
	}
	if (scope == "year") {
		cellClass = "DateHeatmap_YearCell";
		if (count > 100) {
			countClass = "DateHeatmap_Cell_2";
		} else if (count > 50) {
			countClass = "DateHeatmap_Cell_1";
		} else if (count > 0) {
			countClass = "DateHeatmap_Cell_0";
		}
	}

	return (
		<div id={id} className={`${cellClass} ${countClass}`}></div>
	)
}

export default DateHeatmap;
