import React, { useMemo } from 'react';
import _ from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import "css/accounts/AccountPageWrapper.css";


const AccountPageWrapper = ({ children }) => {
	const navigate = useNavigate();

	const accountPage = useMemo(() => {
		const url = window.location.pathname;
		const prefix = "/account";
		return url.substring(prefix.length+1);
	}, []);

	const options = [{
		page: "",
		name: "Details"
	}, {
		page: "public",
		name: "Public"
	}, {
		page: "analytics",
		name: "Analytics"
	}];

	return (
		<Container>
			<Row>
				<h1>My Account</h1>
			</Row>
			<Row>
				<Col xs={3} sm={2} className="AccountPageWrapper_Sidebar">
					{
						_.map(options, ({ page, name }) => {

							const className = (
								page == accountPage ? "AccountPageWrapper_Category_Selected" : "AccountPageWrapper_Category" 
							);

							return (
								<div 
									key={page}
									className={className}
									onClick={() => navigate(`/account/${page}`)}
								>
									{name}
								</div>
							);
						})
					}
				</Col>
				<Col>
		 			{children}
				</Col>
			</Row>
		</Container>
	);
}

export default AccountPageWrapper;
