import React, { useState } from 'react';
import moment from 'moment';
import { Container, Button, Row, Col, Input } from 'reactstrap';
import AccountPageWrapper from 'code/accounts/components/AccountPageWrapper';
import { useAccountPerformanceAnalyticsQuery } from 'code/accounts/hooks';
import { DateHeatmap } from 'code/common/components';


const AccountAnalytics = () => {
	const [scope, setScope] = useState("day");
	const [year, setYear] = useState(moment().year());

	return (
		<AccountPageWrapper>
			<Row>
				<Col>
					<Button onClick={() => setScope("day")}>
						Day
					</Button>
				</Col>
				<Col>
					<Button onClick={() => setScope("month")}>
						Month
					</Button>
				</Col>
				<Col>
					<Button onClick={() => setScope("year")}>
						Year
					</Button>
				</Col>
				<Col>
					<Input 
						value={year}
						type="number"
						onChange={(event) => {
							const newYear = event.target.value;
							if (newYear < 1990 || newYear > moment().year()) {
								return
							}
							setYear(newYear);
						}}
					/>
				</Col>
			</Row>
			<Row>
				<HeatmapComponent scope={scope} year={year} />
			</Row>
		</AccountPageWrapper>
	);
}


const HeatmapComponent = ({ scope, year }) => {
	const { data } = useAccountPerformanceAnalyticsQuery({ year, scope }); 

	if (data == undefined) {
		return null;
	}

	return <DateHeatmap data={data} year={year} scope={scope} />
}

export default AccountAnalytics;